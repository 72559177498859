import { ISelectItemData } from "./Components/FormElements/RenderDropDownSelect";
import { ISingleCountryStore } from "./axios/getBrands";
import {
  AllowedPaymentMethod,
  PromocodeInterfaces,
  PromoMerchantVisibility,
} from "./Components/TargetedTools/types";
import { action as TSaction } from "ts-action";
export * from "./constants/account-manager";

export enum Statuses {
  Inactive = 0,
  Active = 1,
  In_Progress = 2,
  Closed = 3,
  Churned = 4,
}

export const brandStatuses = [
  {
    label: "Inactive",
    value: Statuses.Inactive,
  },
  {
    label: "Active",
    value: Statuses.Active,
  },
  {
    label: "In Progress",
    value: Statuses.In_Progress,
  },
  {
    label: "Closed",
    value: Statuses.Closed,
  },
  {
    label: "Churned",
    value: Statuses.Churned,
  },
];

export const paymentTermsSelectItems: ISelectItemData[] = [
  {
    value: 0,
    label: "Monthly",
  },
  {
    value: 1,
    label: "Quarterly",
  },
  {
    value: 2,
    label: "Annually",
  },
];
export const posSelectItems: ISelectItemData[] = [
  {
    value: "other",
    label: "Other",
  },
  {
    value: "none",
    label: "None",
  },
];

/**
 * @name REVIEWS_EPISODE_VII_A_NEW_DESIGN
 * @description
 * # REVIEWS_EPISODE_VII_A_NEW_DESIGN
 * this is the time when we start considering bad reviews issues.
 * it is this time Thu Aug 23 2018 00:00:00 GMT+0200
 * @type {number}
 */
export const REVIEWS_EPISODE_VII_A_NEW_DESIGN = 1534975200000;

// REDUX

export const genericAction = (action: string) => ({
  fulfilled: `${action}_FULFILLED`,
  rejected: `${action}_REJECTED`,
  requested: `${action}_REQUESTED`,
  reset: `${action}_RESET`,
});
const ACTION_LOGIN = "ACTION_LOGIN";
export const loginAction = genericAction(ACTION_LOGIN);

const ACTION_LOGOUT = "ACTION_LOGOUT";
export const logoutAction = genericAction(ACTION_LOGOUT);

const ACTION_GET_COUNTRIES = "ACTION_GET_COUNTRIES";
export const getCountriesAction = genericAction(ACTION_GET_COUNTRIES);

const ACTION_GET_BRANDS = "ACTION_GET_BRANDS";
export const getBrandsAction = genericAction(ACTION_GET_BRANDS);

const ACTION_GET_BRAND_DETAILS = "ACTION_GET_BRAND_DETAILS";
export const getBrandDetailsAction = genericAction(ACTION_GET_BRAND_DETAILS);

// export brand's foodics menu
const ACTION_EXPORT_FOODICS_MENU = "ACTION_EXPORT_FOODICS_MENU";
export const exportFoodicsMenuAction = genericAction(
  ACTION_EXPORT_FOODICS_MENU
);

const ACTION_GET_BRAND_WIZARD_INFO = "ACTION_GET_BRAND_WIZARD_INFO";
export const getBrandWizardInfoAction = genericAction(
  ACTION_GET_BRAND_WIZARD_INFO
);
const ACTION_GET_WATCHERS = "ACTION_GET_WATCHERS";
export const getWatchersAction = genericAction(ACTION_GET_WATCHERS);
const ACTION_DELETE_WATCHER = "ACTION_DELETE_WATCHER";
export const deleteWatcherAction = genericAction(ACTION_DELETE_WATCHER);

export const ACTION_FILTER_BRANDS = "ACTION_FILTER_BRANDS";
export const ACTION_ENABLE_LOAD_MORE = "ACTION_ENABLE_LOAD_MORE";
export const ACTION_DISABLE_LOAD_MORE = "ACTION_DISABLE_LOAD_MORE";
export const ACTION_RESET_FILTER_BRANDS = "ACTION_RESET_FILTER_BRANDS";
export const ACTION_ADD_PAGE = "ACTION_ADD_PAGE";
export const ACTION_SUB_PAGE = "ACTION_SUB_PAGE";
export const ACTION_RESET_PAGE = "ACTION_RESET_PAGE";
export const ACTION_RESET_EDIT_BRANDS = "ACTION_RESET_EDIT_BRANDS";

export const ACTION_RESET_PAYMENT = "ACTION_RESET_PAYMENT";
export const ACTION_RESET_PAYMENT_LOGS = "ACTION_RESET_PAYMENT_LOGS";
export const ACTION_GET_BRANDS_STATISTICS_SUCCESS =
  "ACTION_GET_BRANDS_STATISTICS_SUCCESS";

export const ACTION_FILL_EDIT_BRAND = "ACTION_FILL_EDIT_BRAND";
const ACTION_EDIT_BRAND = "ACTION_EDIT_BRAND";
export const editBrandAction = genericAction(ACTION_EDIT_BRAND);
export const ACTION_EDIT_BRAND_MUTATION_SUCCESS =
  "ACTION_EDIT_BRAND_MUTATION_SUCCESS";

const ACTION_ADD_BRAND = "ACTION_ADD_BRAND";
export const addBrandAction = genericAction(ACTION_ADD_BRAND);
export const ACTION_CLEAR_PREV_STATE = "ACTION_CLEAR_PREV_STATE";
export const clearPrevStateAction = TSaction(ACTION_CLEAR_PREV_STATE);
const ACTION_GET_BRAND_FAILED_OPERATIONS_NUMBER =
  "ACTION_GET_BRAND_FAILED_OPERATIONS_NUMBER";
const ACTION_GET_BRAND_REGISTERED_CUSTOMERS_NUMBER =
  "ACTION_GET_BRAND_REGISTERED_CUSTOMERS_NUMBER";
const ACTION_GET_BRAND_REVIEWS_NUMBER = "ACTION_GET_BRAND_REVIEWS_NUMBER";
const ACTION_GET_BRAND_CONVERSION_RATE = "ACTION_GET_BRAND_CONVERSION_RATE";
const ACTION_GET_BRAND_REDEMPTION_RATE = "ACTION_GET_BRAND_REDEMPTION_RATE";
const ACTION_GET_BRAND_REDEMPTION_NUMBER = "ACTION_GET_BRAND_REDEMPTION_NUMBER";
const ACTION_GET_BRAND_TEMP_CUSTOMERS_NUMBER =
  "ACTION_GET_BRAND_TEMP_CUSTOMERS_NUMBER";
const ACTION_GET_BRAND_VISITS_NUMBER = "ACTION_GET_BRAND_VISITS_NUMBER";
const ACTION_GET_BRAND_UNRESOLVED_ISSUES_NUMBER =
  "ACTION_GET_BRAND_UNRESOLVED_ISSUES_NUMBER";

export const getBrandFailedOperationsNumberAction = genericAction(
  ACTION_GET_BRAND_FAILED_OPERATIONS_NUMBER
);
export const getBrandRegisteredCustomerNumberAction = genericAction(
  ACTION_GET_BRAND_REGISTERED_CUSTOMERS_NUMBER
);
export const getBrandReviewsNumberAction = genericAction(
  ACTION_GET_BRAND_REVIEWS_NUMBER
);
export const getBrandConversionRateAction = genericAction(
  ACTION_GET_BRAND_CONVERSION_RATE
);
export const getBrandRedemptionRateAction = genericAction(
  ACTION_GET_BRAND_REDEMPTION_RATE
);
export const getBrandRedemptionNumberAction = genericAction(
  ACTION_GET_BRAND_REDEMPTION_NUMBER
);
export const getBrandTempCustomersNumberAction = genericAction(
  ACTION_GET_BRAND_TEMP_CUSTOMERS_NUMBER
);
export const getBrandVisitsNumberAction = genericAction(
  ACTION_GET_BRAND_VISITS_NUMBER
);
export const getBrandUnresolvedIssuesNumberAction = genericAction(
  ACTION_GET_BRAND_UNRESOLVED_ISSUES_NUMBER
);

const ACTION_VERIFY_PAYMENT = "ACTION_VERIFY_PAYMENT";
export const verifyPaymentAction = genericAction(ACTION_VERIFY_PAYMENT);

const ACTION_CONFIRM_PAYMENT = "ACTION_CONFIRM_PAYMENT";
export const confirmPaymentAction = genericAction(ACTION_CONFIRM_PAYMENT);

const ACTION_GET_PAYMENT_LOGS = "ACTION_GET_PAYMENT_LOGS";
export const getPaymentLogsAction = genericAction(ACTION_GET_PAYMENT_LOGS);
const ACTION_ADD_WATCHERS = "ACTION_ADD_WATCHERS";
export const addWatchersAction = genericAction(ACTION_ADD_WATCHERS);

const ACTION_GET_REACH_CAMPAIGNS = "ACTION_GET_REACH_CAMPAIGNS";
export const getReachCampaignsAction = genericAction(
  ACTION_GET_REACH_CAMPAIGNS
);

const ACTION_GET_REACH_QUOTAS = "ACTION_GET_REACH_QUOTAS";
export const getReachQuotasAction = genericAction(ACTION_GET_REACH_QUOTAS);

// Get Country's stores types
const ACTION_GET_COUNTRY_STORES = "ACTION_GET_COUNTRY_STORES";
export const getCountryStoresAction = genericAction(ACTION_GET_COUNTRY_STORES);
const ACTION_GET_ACTIVE_BRANDS = "ACTION_GET_ACTIVE_BRANDS";
export const getActiveBrandsAction = genericAction(ACTION_GET_ACTIVE_BRANDS);
export const ACTION_GET_ALL_COUNTRY_STORES = "ACTION_GET_ALL_COUNTRY_STORES";
export interface IGetCountryStoresRequestedAction {
  type: typeof getCountryStoresAction.requested;
  payload: {
    promo_id?: string;
    country_code: any;
  };
}
export interface IGetCountryStoresFulfilledAction {
  type: typeof getCountryStoresAction.fulfilled;
  payload: ISingleCountryStore[];
}
export interface IGetCountryStoresRejectedAction {
  type: typeof getCountryStoresAction.rejected;
  payload: Error;
}
export interface IGetAllCountryStoresAction {
  type: typeof ACTION_GET_ALL_COUNTRY_STORES;
  payload: ISingleCountryStore[];
}
export type getCountriesActionTypes =
  | IGetCountryStoresRequestedAction
  | IGetCountryStoresFulfilledAction
  | IGetCountryStoresRejectedAction
  | IGetAllCountryStoresAction;

// Get Country's stores types
const ACTION_CHECK_PROMO_AVAILABILITY = "ACTION_CHECK_PROMO_AVAILABILITY";
export const checkPromoAvailabilityAction = genericAction(ACTION_CHECK_PROMO_AVAILABILITY);
const ACTION_CREATE_PROMO_CODES = "ACTION_CREATE_PROMO_CODES";
export const createPromoCodeAction = genericAction(ACTION_CREATE_PROMO_CODES);

const ACTION_SEND_SMS_MESSAGE = "ACTION_SEND_SMS_MESSAGE";
export const sendSmsMessageAction = genericAction(ACTION_SEND_SMS_MESSAGE);

const ACTION_SEND_PUSH_NOTIFICATION = "ACTION_SEND_PUSH_NOTIFICATION";
export const sendPushNotificationAction = genericAction(
  ACTION_SEND_PUSH_NOTIFICATION
);

const ACTION_GET_SYSTEM_PROMO_CODE = "ACTION_GET_SYSTEM_PROMO_CODE";
export const getSystemPromoCodesAction = genericAction(
  ACTION_GET_SYSTEM_PROMO_CODE
);
export const ACTION_GET_PROMO_CODE = "ACTION_GET_PROMO_CODE";
export const ACTION_RESET_PROMO_CODE = "ACTION_RESET_PROMO_CODE";
export const getPromoCodeAction = genericAction(ACTION_GET_PROMO_CODE);
// export interface ICreatePromoCode {
//   type: typeof createPromoCodeAction.requested;
//   payload: IPromCode;
// }

// export interface ICreatePromoCodeSuccess {
//   type: typeof createPromoCodeAction.fulfilled;
//   payload: string;
// }
// export interface ICreatePromoCodeFailed {
//   type: typeof createPromoCodeAction.rejected;
//   payload: string;
// }

// export type createPromoCodeActionType =
//   | ICreatePromoCode
//   | ICreatePromoCodeSuccess
//   | ICreatePromoCodeFailed;

export interface ISendSmsMessage {
  type: typeof sendSmsMessageAction.requested;
  payload: FormData;
}

export interface ISendSmsMessageSuccess {
  type: typeof sendSmsMessageAction.fulfilled;
  payload: { success: number; failed: number };
}
export interface ISendSmsMessageFailed {
  type: typeof sendSmsMessageAction.rejected;
  payload: string;
}

export type sendSmsMessageActionType =
  | ISendSmsMessage
  | ISendSmsMessageSuccess
  | ISendSmsMessageFailed;

export interface ISendPushNotification {
  type: typeof sendPushNotificationAction.requested;
  payload: FormData;
}

export interface ISendPushNotificationSuccess {
  type: typeof sendPushNotificationAction.fulfilled;
  payload: string;
}
export interface ISendPushNotificationFailed {
  type: typeof sendPushNotificationAction.rejected;
  payload: any;
}

export type sendPushNotificationActionType =
  | ISendPushNotification
  | ISendPushNotificationSuccess
  | ISendPushNotificationFailed;

export interface IStore {
  name: string;
  id: string;
  store_fund_percentage?: any;
  country_iso_code: string;
  max_discount_value?: any;
  masked_value?: any;
  masked_promo_code?: string;
  min_threshold?: any;
  max_cashback_value?: any;
  expires_after?: any;
}
export interface ISinglePromoCode {
  category: "points_multiplier" | "discount";
  end_time: number;
  discount_value: number;
  created_at: number;
  promo_code: string;
  type: string;
  title: string;
  max_discount_value: number;
  cashback_value?: number;
  max_cashback_value?: number;
  max_usage_times: number;
  min_threshold: number;
  start_time: number;
  updated_at: number;
  user_id: string;
  multiplier_value: string;
  id: string;
  desc: string;
  ar_title: string;
  ar_desc: string;
  stores_fund_percentage: any;
  expired_at: string;
  label: string;
  expired_by_admin_at?: number;
  stores: IStore[];
  ordering_type: string;
  targeting_method: string;
  suggested: number;
  customers_file_path: string;
  promo_merchant_visibility?: PromoMerchantVisibility;
  allowed_payment_method?: AllowedPaymentMethod;
  is_compensational: boolean;
  customers_file_name: string;
  customers_file_names: string[];
  customers_count: number;
  rank: number;
  is_exclusive: 0 | 1;
}

export interface ISinglePromotionStore {
  name: string;
  id: string;
}
export interface ISystemPromoCodes {
  promos: ISinglePromoCode[];
}

export interface IPage {
  page: number;
  per_page: number;
  filters?: PromocodeInterfaces.IPromocodeFilter;
}
export interface IGetSystemPromoCodes {
  type: typeof getSystemPromoCodesAction.requested;
  payload: IPage;
}
export interface IGetSystemPromoCodesSuccess {
  type: typeof getSystemPromoCodesAction.fulfilled;
  payload: ISinglePromoCode[];
}

export interface IGetSystemPromoCodesFailed {
  type: typeof getSystemPromoCodesAction.rejected;
  payload: Error;
}

export type IGetSystemPromoCodesActionTypes =
  | IGetSystemPromoCodesSuccess
  | IGetSystemPromoCodes
  | IGetSystemPromoCodesFailed;

const DASHBOARD_PREVIEW_LOGGER = "DASHBOARD_PREVIEW_LOGGER";
export const dashboardPreviewLoggerAction = genericAction(
  DASHBOARD_PREVIEW_LOGGER
);

// promoted brands
// fetch
const ACTION_GET_PROMOTED_BRANDS = "ACTION_GET_PROMOTED_BRANDS";
export const ACTION_PROMOED_ADD_PAGE = "ACTION_PROMOED_ADD_PAGE";
export const ACTION_PROMOTED_DISABLE_LOAD_MORE =
  "ACTION_PROMOTED_DISABLE_LOAD_MORE";
export const ACTION_PROMOTED_ENABLE_LOAD_MORE =
  "ACTION_PROMOTED_ENABLE_LOAD_MORE";
export const ACTION_FILTER_PROMOTED_BRANDS = "ACTION_FILTER_PROMOTED_BRANDS";
export const ACTION_GET_PROMOTED_BRANDS_STATISTICS_SUCCESS =
  "ACTION_GET_PROMOTED_BRANDS_STATISTICS_SUCCESS";
export const ACTION_RESET_FILTER_PROMOTED_BRANDS =
  "ACTION_RESET_FILTER_PROMOTED_BRANDS";
export const ACTION_PROMOTED_RESET_PAGE = "ACTION_PROMOTED_RESET_PAGE";
export const ACTION_PROMOTRED_SUB_PAGE = "ACTION_PROMOTRED_SUB_PAGE";
export const getPromotedBrandsAction = genericAction(
  ACTION_GET_PROMOTED_BRANDS
);
// edit
export const ACTION_FILL_EDIT_PROMOTED_BRAND =
  "ACTION_FILL_EDIT_PROMOTED_BRAND";
const ACTION_EDIT_PROMOTED_BRAND = "ACTION_EDIT_PROMOTED_BRAND";
export const editPromotedBrandAction = genericAction(
  ACTION_EDIT_PROMOTED_BRAND
);
export const ACTION_EDIT_PROMOTED_BRAND_MUTATION_SUCCESS =
  "ACTION_EDIT_PROMOTED_BRAND_MUTATION_SUCCESS";
export const ACTION_RESET_EDIT_PROMOTED_BRANDS =
  "ACTION_RESET_EDIT_PROMOTED_BRANDS";

// promoted brands section title actions
export const ACTION_EDIT_PROMOTED_BRAND_TITLE =
  "ACTION_EDIT_PROMOTED_BRAND_TITLE";
export const ACTION_GET_PROMOTED_BRAND_TITLE =
  "ACTION_GET_PROMOTED_BRAND_TITLE";

export const editPromotedBrandsTitleAction = genericAction(
  ACTION_EDIT_PROMOTED_BRAND_TITLE
);
export const getPromotedBrandsTitleAction = genericAction(
  ACTION_GET_PROMOTED_BRAND_TITLE
);

// cashback details
export const ACTION_CREATE_CASHBACK_DETAILS = "ACTION_CREATE_CASHBACK_DETAILS";
export const createCashbackDetailsAction = genericAction(
  ACTION_CREATE_CASHBACK_DETAILS
);

export const ACTION_EDIT_CASHBACK_DETAILS = "ACTION_EDIT_CASHBACK_DETAILS";
export const editCashbackDetailsAction = genericAction(
  ACTION_EDIT_CASHBACK_DETAILS
);

export const ACTION_GET_CASHBACK_DETAILS = "ACTION_GET_CASHBACK_DETAILS";
export const getCashbackDetailsAction = genericAction(
  ACTION_GET_CASHBACK_DETAILS
);
export const ACTION_CLEAR_PREV_CASHBACK_DETAILS =
  "ACTION_CLEAR_PREV_CASHBACK_DETAILS";

// ams actions

export const ACTION_GET_BRANDS_WITH_TASKS = "ACTION_GET_BRANDS_WITH_TASKS";
export const getBrandsWithTasksAction = genericAction(
  ACTION_GET_BRANDS_WITH_TASKS
);

export const ACTION_SET_SELECTED_TASKS = "ACTION_SET_SELECTED_TASKS";
export const ACTION_UPDATE_BRANDS_WITH_TASKS_FILTER =
  "ACTION_UPDATE_BRANDS_WITH_TASKS_FILTER";
export const ACTION_GET_POINTS_OF_CONTACTS = "ACTION_GET_POINTS_OF_CONTACTS";
export const getPointsOfContactsAction = genericAction(
  ACTION_GET_POINTS_OF_CONTACTS
);

export const ACTION_GET_CONTACT_TEMPLATES = "ACTION_GET_CONTACT_TEMPLATES";
export const getContactTemplatesAction = genericAction(
  ACTION_GET_CONTACT_TEMPLATES
);

export const ACTION_SELECT_STORE_ID = "ACTION_SELECT_STORE_ID";
export const ACTION_UPDATE_SELECTED_BRAND = "ACTION_UPDATE_SELECTED_BRAND";

export const ACTION_RESOLVE_TASKS = "ACTION_RESOLVE_TASKS";
export const resolveTasksAction = genericAction(ACTION_RESOLVE_TASKS);

export const ACTION_GET_RESOLVED_ACTIONS = "ACTION_GET_RESOLVED_ACTIONS";
export const getResolvedActionsAction = genericAction(
  ACTION_GET_RESOLVED_ACTIONS
);

export const ACTION_RESET_APP_STATE = "ACTION_RESET_APP_STATE";
export const ACTION_GET_SELECTED_ACTIONS = "ACTION_GET_SELECTED_ACTIONS";

export const ACTION_GET_POS_PROVIDERS = "ACTION_GET_POS_PROVIDERS";
export const getPosProvidersAction = genericAction(ACTION_GET_POS_PROVIDERS);

export const ACTION_CREATE_BRAND_POS_ENTITY = "ACTION_CREATE_BRAND_POS_ENTITY";
export const createBrandPosEntityAction = genericAction(
  ACTION_CREATE_BRAND_POS_ENTITY
);

export const ACTION_GEN_BRAND_POS_PAYMENT_METHOD =
  "ACTION_GEN_BRAND_POS_PAYMENT_METHOD";
export const genBrandPosPaymentMethodAction = genericAction(
  ACTION_GEN_BRAND_POS_PAYMENT_METHOD
);

export const ACTION_UPDATE_BRAND_POS_ENTITY = "ACTION_UPDATE_BRAND_POS_ENTITY";
export const updateBrandPosEntityAction = genericAction(
  ACTION_UPDATE_BRAND_POS_ENTITY
);

export const ACTION_GET_BRAND_POS_ENTITIES = "ACTION_GET_BRAND_POS_ENTITIES";
export const getBrandPosEntitiesAction = genericAction(
  ACTION_GET_BRAND_POS_ENTITIES
);

export const ACTION_DELETE_BRAND_POS_Entity = "ACTION_DELETE_BRAND_POS_Entity";
export const deleteBrandPosEntityAction = genericAction(
  ACTION_DELETE_BRAND_POS_Entity
);

export const ACTION_UPDATE_POS_ENTITIES_LOADING_STATUS =
  "ACTION_UPDATE_POS_ENTITIES_LOADING_STATUS";

export const ACTION_SELECT_BRAND_POS_ENTITY = "ACTION_SELECT_BRAND_POS_ENTITY";

export const ACTION_EXPORT_BRAND_POS_MENU = "ACTION_EXPORT_BRAND_POS_MENU";
export const exportBrandPosMenuAction = genericAction(
  ACTION_EXPORT_BRAND_POS_MENU
);
export const ACTION_SET_BRAND_POS_ENTITY = "ACTION_SET_BRAND_POS_ENTITY";

const ACTION_GET_BRAND_POS_ORDERING_INTEGRATION_STATUS =
  "ACTION_GET_BRAND_POS_ORDERING_INTEGRATION_STATUS";
export const getBrandPosOrderingIntegrationStatusAction = genericAction(
  ACTION_GET_BRAND_POS_ORDERING_INTEGRATION_STATUS
);

const ACTION_GET_BRAND_POS_LOYALTY_INTEGRATION_STATUS =
  "ACTION_GET_BRAND_POS_LOYALTY_INTEGRATION_STATUS";
export const getBrandPosLoyaltyIntegrationStatusAction = genericAction(
  ACTION_GET_BRAND_POS_LOYALTY_INTEGRATION_STATUS
);
